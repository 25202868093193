define("discourse/plugins/discourse-multilingual/discourse/components/multilingual-uploader", ["exports", "@glimmer/component", "discourse/lib/uppy/uppy-upload", "@ember/owner", "discourse-common/helpers/i18n", "@ember/service", "discourse/helpers/loading-spinner", "discourse-common/helpers/d-icon", "@ember/render-modifiers/modifiers/did-insert", "discourse-common/utils/decorators", "@glimmer/tracking", "@ember/component", "@ember/template-factory"], function (_exports, _component, _uppyUpload, _owner, _i18n, _service, _loadingSpinner, _dIcon, _didInsert, _decorators, _tracking, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MultilingualUploader extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "message", [_tracking.tracked]))();
    #message = (() => (dt7948.i(this, "message"), void 0))();
    uppyUpload = (() => new _uppyUpload.default((0, _owner.getOwner)(this), {
      id: this.args.id,
      type: "yml",
      preventDirectS3Uploads: true,
      uploadUrl: `/admin/multilingual/${this.args.uploadType}s`,
      validateUploadedFilesOptions: {
        skipValidation: true
      },
      uploadDone: () => {
        // wait for message that uploaded file is processed.
      }
    }))();
    constructor() {
      super(...arguments);
      this.messageBus.subscribe("/uploads/" + this.uppyUpload.config.type, this.handleMessage);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.messageBus.unsubscribe("/uploads/" + this.uppyUpload.config.type, this.handleMessage);
    }
    get addDisabled() {
      return this.uppyUpload.uploading;
    }
    handleMessage(msg) {
      if (msg.uploaded) {
        this.message = (0, _i18n.default)("uploaded");
        this.done();
      } else if (msg.errors) {
        this.message = msg.errors[0];
      }
      setTimeout(() => {
        this.message = null;
      }, 10000);
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleMessage", [_decorators.bind]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          id="multilingual-uploader"
          class="multilingual-uploader {{@uploadType}}"
        >
          {{#if this.message}}
            <span>{{this.message}}</span>
          {{/if}}
    
          {{#if this.uppyUpload.uploading}}
            {{loadingSpinner size="small"}}
            <span>{{i18n "uploading"}}</span>
          {{/if}}
    
          <label class="btn btn-default {{if this.addDisabled 'disabled'}}">
            {{icon "upload"}}
            {{i18n "upload"}}
            <input
              {{didInsert this.uppyUpload.setup}}
              class="hidden-upload-field"
              disabled={{this.addDisabled}}
              type="file"
              accept="text/x-yaml"
            />
          </label>
        </div>
      
    */
    {
      "id": "keffGEst",
      "block": "[[[1,\"\\n    \"],[10,0],[14,1,\"multilingual-uploader\"],[15,0,[29,[\"multilingual-uploader \",[30,1]]]],[12],[1,\"\\n\"],[41,[30,0,[\"message\"]],[[[1,\"        \"],[10,1],[12],[1,[30,0,[\"message\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"uppyUpload\",\"uploading\"]],[[[1,\"        \"],[1,[28,[32,0],null,[[\"size\"],[\"small\"]]]],[1,\"\\n        \"],[10,1],[12],[1,[28,[32,1],[\"uploading\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,\"label\"],[15,0,[29,[\"btn btn-default \",[52,[30,0,[\"addDisabled\"]],\"disabled\"]]]],[12],[1,\"\\n        \"],[1,[28,[32,2],[\"upload\"],null]],[1,\"\\n        \"],[1,[28,[32,1],[\"upload\"],null]],[1,\"\\n        \"],[11,\"input\"],[24,0,\"hidden-upload-field\"],[16,\"disabled\",[30,0,[\"addDisabled\"]]],[24,\"accept\",\"text/x-yaml\"],[24,4,\"file\"],[4,[32,3],[[30,0,[\"uppyUpload\",\"setup\"]]],null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@uploadType\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-multilingual/discourse/components/multilingual-uploader.js",
      "scope": () => [_loadingSpinner.default, _i18n.default, _dIcon.default, _didInsert.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MultilingualUploader;
});