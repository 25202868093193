define("discourse/plugins/discourse-multilingual/discourse/components/language-switcher-menu", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "discourse/helpers/concat-class", "discourse-i18n", "truth-helpers/helpers/eq", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _service, _concatClass, _discourseI18n, _eq, _multilingualRoute, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LanguageSwitcherMenu extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get currentLocale() {
      return _discourseI18n.default.currentLocale();
    }
    change(locale) {
      (0, _multilingualRoute.addParam)(_multilingualRoute.localeParam, locale, {
        add_cookie: true,
        ctx: this
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "change", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="language-switcher-menu">
          <ul>
            {{#each this.site.interface_languages as |l|}}
              <li>
                {{! template-lint-disable no-invalid-interactive }}
                <a
                  class={{concatClass
                    "ls-language"
                    (if (eq l.locale this.currentLocale) "current")
                  }}
                  {{on "click" (fn this.change l.locale)}}
                  label={{l.name}}
                >
                  {{l.name}}
                </a>
              </li>
            {{/each}}
          </ul>
        </div>
      
    */
    {
      "id": "AuhWQlFl",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"language-switcher-menu\"],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"site\",\"interface_languages\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[12],[1,\"\\n\"],[1,\"            \"],[11,3],[16,0,[28,[32,0],[\"ls-language\",[52,[28,[32,1],[[30,1,[\"locale\"]],[30,0,[\"currentLocale\"]]],null],\"current\"]],null]],[16,\"label\",[30,1,[\"name\"]]],[4,[32,2],[\"click\",[28,[32,3],[[30,0,[\"change\"]],[30,1,[\"locale\"]]],null]],null],[12],[1,\"\\n              \"],[1,[30,1,[\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"l\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-multilingual/discourse/components/language-switcher-menu.js",
      "scope": () => [_concatClass.default, _eq.default, _modifier.on, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LanguageSwitcherMenu;
});